import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class TypeDetailService {
    readTypeDetailQuery(){
        var query = `
            query{
                GetTypeDetail {
                    type_detail_id
                    type_detail_name
                    active_flag
                    group_type_id
                    group_type_name
                    material_group_type_id
                    material_group_type_name
                    tax_code
                    commision_status
                    term_of_payment
                    created_at
                    last_update
                }
            }
        `;
        return query;
    }

    async addQuery(variables){
        var query = gql`
            mutation($data: type_detail_new!){
                CreateTypeDetail(New: $data){
                    response
                }
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`
            mutation($id: String!, $data: type_detail_new!){
                UpdateTypeDetail(TypeDetail_Id: $id, New: $data ){
                    response
                }
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`
            mutation( $id: String! ){
                DeleteTypeDetail( TypeDetail_Id: $id ){
                    response
                }
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async getItemGroup(){
        var query = gql`
            query{
                GetItemGroup{
                    item_group_id
                    item_group_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = [];
        for (let i = 0; i < result.data.GetItemGroup.length; i++) {
            var str = { 
                value: result.data.GetItemGroup[i].item_group_id, 
                label: result.data.GetItemGroup[i].item_group_name
            }
            data.push(str);
        }
        return data
    }

    async getGroupType(){        
        var query = gql`
            query{
                GetGroupType{
                    group_type_id
                    group_type_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = [];
        for (let i = 0; i < result.data.GetGroupType.length; i++) {
            var str = { 
                value: result.data.GetGroupType[i].group_type_id, 
                label: result.data.GetGroupType[i].group_type_name
            }
            data.push(str);
        }
        return data
    }

}

export default new TypeDetailService();